// 背景图默认样式
.bg__default {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow: scroll;
    box-sizing: border-box;
    padding-bottom: 24px;

    .header__wrapper {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon {
            color: #181f38;
            font-size: 44px;
        }
    }

    .bg1 {
        position: absolute;
        width: 144px;
        height: 102px;
        top: 44px;
        left: 0;
    }

    .main__wrapper {
        height: calc(100vh - 44px);
        width: calc(100% - 80px);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #010b27;

        .logo {
            width: 141px;
            height: 20px;
            margin-top: 30px;
            position: relative;
        }

        .content__title1 {
            margin-top: 92px;
            font-size: 48px;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            span {
                z-index: 2;
                position: relative;

                .bg__circle {
                    width: 46px;
                    height: 46px;
                    border-radius: 46px;
                    background-color: #5069fa;
                    position: absolute;
                    top: -8px;
                    left: -14px;
                    z-index: -1;
                }

                .bg__circle_en {
                    top: -2px;
                    left: -9px;
                }
            }
        }

        .content__title2 {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            font-size: 48px;
        }
        .content__title_en {
            width: calc(100% + 24px);
        }
        .content__title_ja {
            font-size: 38px;
            width: calc(100% + 24px);
        }

        .bg2 {
            width: 239.8px;
            height: 323px;
            margin-left: 13px;
            margin-top: -42px;
            z-index: -1;
            position: relative;
        }

        .download__button {
            width: 280px;
            height: 40px;
            margin-top: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background-color: #2c36de;
            font-size: 14px;
            color: #ffffff;
        }
    }
}
