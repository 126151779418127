// 背景图默认样式
.bg__default {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.wrapper {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #fbfbfb;

    .bg1 {
        position: absolute;
        left: 0;
        width: 360px;
        height: 200px;
    }

    .bg1_en {
        top: 53px;
        left: -73px;
    }

    .main__wrapper {
        box-sizing: border-box;
        height: 1008px;
        padding-top: 80px;

        .main__content {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            width: 1240px;
            margin: 0 auto;
            padding-top: 140px;
            padding-left: 78px;
            color: #010b27;

            .content__title1 {
                position: relative;
                z-index: 2;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: 90px;
                font-family: PingFangSC;
                line-height: 1.22;

                .bg3 {
                    position: absolute;
                    top: -18px;
                    left: -21px;
                    z-index: -1;
                    width: 76px;
                    height: 76px;
                    background-color: #5069fa;
                    border-radius: 76px;
                }
            }

            .content__title1_en {
                font-family: SanFranciscoDisplay;
            }

            .content__title2 {
                margin-top: 8px;
                margin-left: -78px;
                font-size: 90px;
                font-family: PingFangSC;
                line-height: 1.22;
            }

            .content__title2_en {
                margin-left: -138px;
                font-family: SanFranciscoDisplay;
            }

            .content__tips1 {
                margin-top: 14px;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                font-size: 32px;
            }

            .content__tips2 {
                max-width: 396px;
                margin-top: 8px;
                font-size: 24px;
                opacity: 0.6;
            }

            .content__download {
                display: flex;
                justify-content: flex-start;
                padding-top: 14px;

                .download__ORCode {
                    flex-shrink: 0;
                    box-sizing: border-box;
                    width: 210px;
                    height: 210px;
                    overflow: hidden;
                    background-color: #fff;
                    border: solid 1px rgba(1, 11, 39, 0.2);
                    border-radius: 16px;
                }

                .download__button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 36px;

                    .button__wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 144px;
                        height: 48px;
                        overflow: hidden;
                        background-color: #fff;
                        border: solid 1px rgba(1, 11, 39, 0.2);
                        border-radius: 8px;
                        cursor: pointer;

                        .picture {
                            position: relative;
                            display: flex;
                            align-items: center;
                            width: 43px;
                            height: 43px;
                        }

                        .button__text {
                            flex: 1;
                            color: #010b27;
                            font-weight: normal;
                            font-size: 14px;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.29;
                            letter-spacing: normal;
                        }

                        .download {
                            position: relative;
                            display: none;
                            width: 24px;
                            height: 24px;
                        }

                        &:hover {
                            background-color: rgba(44, 54, 222, 0.1);
                            border: solid 1px #2c36de;

                            .download {
                                display: block;
                            }

                            .picture,
                            .button__text {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .main__content_en {
            padding-left: 30px;
        }

        .bg2 {
            position: absolute;
            top: 0;
            left: 380px;
            z-index: -1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 850px;
            height: 850px;
        }
    }

    // ---------------- 文案靠右布局 ----------------
    .main__wrapper.right__layout {
        .main__content {
            width: 1404px;
            box-sizing: border-box;
            padding-right: 748px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .bg2 {
            left: auto;
            right: 0;
        }
        .content__title1 {
            width: max-content;
            text-align: right;
        }
        .content__title2 {
            font-size: 82px;
            width: max-content;
            text-align: right;
        }
        .content__tips1 {
            text-align: right;
            max-width: max-content;
        }
        .content__tips2 {
            max-width: max-content;
            text-align: right;
        }
        .content__download {
            padding-left: 78px;
        }
    }
    // ---------------- 文案靠右布局 ----------------
}
